import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { styled } from '@mui/material/styles'
import SVG from 'react-inlinesvg'
import { TransitionGroup, Transition } from 'react-transition-group'

import { IconButton, Paper } from '@mui/material'
import { pink } from '@mui/material/colors'

import Headline from '@objects/headline'

const StyledGatsbyImage = styled(GatsbyImage)(({ theme }) => ({
  width: '85%',
  marginX: 'auto',
  marginTop: 0,
  marginBottom: theme.spacing(6),
  borderRadius: '50%',
  [theme.breakpoints.up('sm')]: {
    margin: theme.spacing(0, 'auto', 20, 'auto'),
    width: '80%',
  },
  [theme.breakpoints.up('lg')]: {
    margin: theme.spacing(0, 'auto', 20, 'auto'),
    width: '75%',
  },
}))

const StyledSVG = styled(SVG)(({ theme }) => ({
  width: '24px',
  '& path': {
    fill: pink['100'],
  },
  [theme.breakpoints.up('md')]: {
    width: '32px',
  },
}))

const StyledTransitionGroup = styled(TransitionGroup)``

export type LinkGroupProps = {
  image?: GT.Contentful.IAsset
  links: Array<{
    id?: string
    url?: string
    label?: string
    icon?: GT.Contentful.IAsset
  }>
}

export default function LinkGroup({
  image,
  links,
}: LinkGroupProps): React.ReactElement {
  const img = image?.gatsbyImageData && getImage(image?.gatsbyImageData)

  const LinkButtonTransitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 1 },
    exited: { opacity: 0 },
    unmounted: { opacity: 0 },
  }

  return (
    <Transition in={true} appear={true} timeout={300}>
      {(state) => (
        <Paper
          sx={{
            opacity: state === 'entered' ? 1 : 0,
            transform: state === 'entered' ? 'scale(1)' : 'scale(0.98)',
            transition: 'opacity 0.2s ease-in-out, transform 0.3s ease-in-out',
            margin: '0 auto',
            marginTop: { xs: 6, sm: 10 },
            paddingY: { xs: 6, sm: 12, md: 16 },
            paddingX: { xs: 4, sm: 8, md: 12 },
            width: { xs: '100%', sm: '70%', md: '65%', lg: '60%' },
            backgroundColor: pink['100'],
            textAlign: 'center',
            borderRadius: 6,
          }}
          elevation={0}
        >
          {!!img && (
            <StyledGatsbyImage image={img} alt="" backgroundColor={pink[100]} />
          )}
          <Headline
            sx={{ textShadow: '0 2px white', marginBottom: '0 !important' }}
          >
            GeisterkatzeART
          </Headline>
          <Headline
            variant="subtitle1"
            sx={{
              textShadow: '0 2px white',
            }}
          >
            Illustration & Design
          </Headline>
          <StyledTransitionGroup
            sx={{
              marginTop: { xs: 4, sm: 8, md: 16 },
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            appear={true}
          >
            {links.map((link, i) => (
              <Transition key={link.id} in={true} timeout={800 + 220 * i}>
                {(state) => (
                  <IconButton
                    href={link.url || ''}
                    target="_blank"
                    rel="noreferrer"
                    sx={{
                      opacity: state === 'entered' ? 1 : 0,
                      transform:
                        state === 'entered' ? 'scale(1)' : 'scale(0.6)',
                      transition:
                        'opacity 0.2s ease-in-out, transform 0.2s ease-in-out',
                      backgroundColor: 'white',
                      '&:hover': {
                        backgroundColor: 'text.secondary',
                      },
                      '&:not(:last-of-type)': {
                        marginRight: { xs: 4, md: 6 },
                      },
                    }}
                    {...LinkButtonTransitionStyles[state]}
                    size="large"
                  >
                    {!!link.icon?.file?.url && (
                      <StyledSVG src={link.icon?.file?.url} />
                    )}
                  </IconButton>
                )}
              </Transition>
            ))}
          </StyledTransitionGroup>
        </Paper>
      )}
    </Transition>
  )
}
