import React from 'react'
import { graphql } from 'gatsby'
import { Container } from '@mui/material'

import Metatags from '@objects/metatags'
import Headline from '@objects/headline'
import LinkGroup from '@components/linkgroup'

export type HomePageProps = {
  data: {
    contentfulGenericPage: GT.GenericPage
  }
}

export default function HomePage({ data }: HomePageProps): React.ReactElement {
  const PageData = data.contentfulGenericPage
  const LinkGroupsModule = PageData.modules.filter(
    (module) => module.__typename === 'ContentfulModuleLinkGroup'
  )

  return (
    <React.Fragment>
      <Metatags
        meta={{
          metaTitle: PageData.metaTitle,
          metaDescription: PageData.metaDescription,
          metaOgtitle: PageData.metaOgtitle,
          metaOgdescription: PageData.metaOgdescription,
          metaOgimage: PageData.metaOgimage?.file?.url,
        }}
      />
      <Container
        sx={{
          paddingTop: { sx: 2, sm: 6, md: 12, lg: 24 },
        }}
      >
        <Headline sx={{ width: '100%', textAlign: 'center' }}>
          Hello there!
        </Headline>
        <Headline sx={{ width: '100%', textAlign: 'center' }} variant="h3">
          Welcome to the GeisterkatzeART Hub <br />
          This is where you find me
        </Headline>

        {LinkGroupsModule.map(
          (LinkGroupModule: GT.Contentful.IContentfulModuleLinkGroup) =>
            LinkGroupModule.links ? (
              <LinkGroup
                key={LinkGroupModule.id}
                image={LinkGroupModule.image}
                links={LinkGroupModule.links}
              />
            ) : null
        )}
      </Container>
    </React.Fragment>
  )
}

export const pageQuery = graphql`
  query HomePageQuery {
    contentfulGenericPage(titleInternal: { eq: "Startseite" }) {
      metaTitle
      metaDescription
      metaOgtitle
      metaOgdescription
      metaOgimage {
        file {
          url
        }
      }
      modules {
        ...ContentfulPageModuleFragment
      }
    }
  }
`
